import React, { useEffect, useState } from 'react';
import './Account.css';
import { IoMdInformationCircleOutline } from "react-icons/io";
import BookCardTem from '../../components/BookCardtem/BookCardTem';
import { useNavigate } from 'react-router-dom';
import SliderCustom from '../../components/BookCardtem/SliderCustom';
import axios from 'axios';
import Responsive from '../../components/BookCardtem/SlickSlider';
import { Spinner } from 'react-bootstrap'; // Importing Spinner from react-bootstrap
import RecentReadResponsive from '../../components/recentRead/RecentReadResponsive';
import RecentReadSlider from '../../components/recentRead/RecentRead';

const RecentRead = () => {
    const [token, setToken] = useState('');
    const [readbook, setRecentBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Loadingrecomded, setLoadingrecomded] = useState(false);
    const navigation = useNavigate();
    const [recomdeddata, setrecomdeddata] = useState([]);

    const handleBookClick = (bookData) => {
        navigation('/BookDetails', { state: { data: bookData } });
    };

    const storedData = localStorage.getItem('user');

    useEffect(() => {
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const TOKEN = parsedData.loginToken;
            setToken(TOKEN);
        } else {
            console.log('No data found in local storage.');
        }
    }, [storedData]);

    useEffect(() => {
        // const fetchRecentRead = async () => {
        //     setLoading(true);
        //     if (token) {
        //         try {
        //             const response = await axios.get('https://new-app-testing-2d30280db142.herokuapp.com/api/get-all-recently-read-book', {
        //                 headers: {
        //                     Authorization: token
        //                 }
        //             });
        //             setRecentBooks(response.data.books.reverse());
        //         } catch (error) {
        //             console.error('Error fetching recently read books:', error.response);
        //         } finally {
        //             setLoading(false);
        //         }
        //     }
        // };
        // fetchRecentRead();
        const fetchRecentReadBooks = async () => {
            setLoading(true);   
            const checktoken = localStorage.getItem('token');
            try {
              const response = await axios.get('https://backend.mysecretlit.com/api/user/getReadBooks', {
                headers: {
                  Authorization: `Bearer ${checktoken}`,
                },
              });
              const readBooks = response.data.data.readHistory || [];
              setRecentBooks(readBooks?.reverse());
            } catch (error) {
              console.log('Error fetching recent read books:', error);
            } finally {
                setLoading(false); // Loading is complete
            }
          };
          fetchRecentReadBooks()
    }, []);

    const fetchBooksByrecamendebook = async () => {
        setLoadingrecomded(true);
        const checktoken = localStorage.getItem('token');

        try {
            const response = await axios.get('https://backend.mysecretlit.com/api/user/getRecommendedBooks?limit=8', {
                headers: {
                  Authorization: `Bearer ${checktoken}`,
                },
              });
             const data = response.data?.data || [];
             
            // const booksArray = data?.map(item => item.book);
            setrecomdeddata(data);
        } catch (error) {
            console.error('Error fetching books:', error);
        } finally {
            setLoadingrecomded(false);
        }
    };

    useEffect(() => {
        fetchBooksByrecamendebook();
    }, []);
    // const mainContainerStyle = {
    //     display: 'block',
    //     height: readbook.length > 0 ? 'auto' : 'revert-layer', // Dynamic height
    // };
    return (
        <div className='recentRead-main' style={{ 
            display: 'block', 
            height: readbook.length === 0 ? '100vh' : (readbook.length >= 6 ? 'auto' : '100%') // Set height based on readbook length
        }}>
            <div className="rowdiv-recent additonRowDIn" style={{ background: 'black' }}>
                <div className="iconDiv">
                    <h1 className="recenredRed-heading" style={{ background: 'black', fontFamily: '"Noto Sans SC", sans-serif' }}>
                        Recently Read
                    </h1>
                </div>
                {loading ? (
                    <div className='spinner-container'>
                        <Spinner animation="border" variant="light" /> {/* Spinner for loading */}
                    </div>
                ) : readbook?.length === 0 ? (
                    <p className='paragrah-no-recenrtbook'>
                        <IoMdInformationCircleOutline style={{ fontSize: '20px', marginRight: '4px' }} />
                        There are no recently read books
                    </p>
                ) : (
                    <div className="slider-container-rednring">
                        {readbook?.map((card, index) => (
                            <div key={index} onClick={() => handleBookClick(card)} style={{ cursor: 'pointer' }} className='div-main-recentrendeing'>
                                <BookCardTem
                                    Bookname={card?.title}
                                    firstTopTag={card?.FirstTopTag}
                                    secTopTag={card?.SecTopTag}
                                    bookcategoriesFirst={card?.category?.name}
                                    bookcategoriesSec={card?.secondaryCategory?.name}
                                    rating={card?.rating}
                                    bookImg={card?.coverImage || card?.bookCoverImage}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            
            <div>
            {readbook && readbook?.length > 0 && (
          <div className="releatedBooks" id='recentRead-book-data-id'>
                              <h1 className="recenredRed-heading" style={{ background: 'black', fontFamily: '"Noto Sans SC", sans-serif',textAlign:'left',paddingBottom:10 }}>
You May Also Like</h1>
                {Loadingrecomded ? (
                    <div className='spinner-container'>
                        <Spinner animation="border" variant="light" /> {/* Spinner for loading */}
                    </div>
                ) : recomdeddata.length <= 3 ? (
                    <RecentReadResponsive showDeleteIcon={false} loading={Loadingrecomded} CardDetails={recomdeddata} />

                    // <Responsive loading={Loadingrecomded} CardDetails={recomdeddata} />
                ) : (
                    <RecentReadSlider showDeleteIcon={false} loading={Loadingrecomded} CardDetails={recomdeddata} />

                    // <SliderCustom loading={Loadingrecomded} CardDetails={recomdeddata} />
                )
                }
            
            </div>
         )}
        </div>
        </div>
    );
};

export default RecentRead;
