import React, { useEffect, useState } from 'react';
import './AudioBookPlayer.css';
import { useNavigate } from 'react-router-dom';
import FormUserNotLogin from '../../components/form/FormUserNotlogin';
import axios from 'axios';
import { Skeleton } from '@mui/material';

const AllAudioBooks = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [checkplan,setcheckplan] = useState()
  const [isLoading, setIsLoading] = useState(true); 
  const [loginuser,setloginuser] = useState()
  useEffect(() => {
    const storedData = localStorage.getItem('user');
    if (storedData) {
        try {
            const parsedData = JSON.parse(storedData);
            if (parsedData && parsedData.user && parsedData.user._id) {
              setloginuser(parsedData.user._id);
            } else {
                console.error('User data or _id is missing');
            }
        } catch (error) {
            console.error('Error parsing user data:', error);
        }
    } else {
        console.log('No data found in local storage.');
    }
}, []);

  const fetchBookscheckPayment= async () => {
    if (!loginuser) return;
    try {
        const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/check-payment-plan/${loginuser}`);
       console.log('asdasd',response.data)
        setcheckplan(response.data)
        // setplandata(response.data.currentPlan)
    } catch (error) {
        console.error('Error fetching books:', error);
    }
  };
useEffect(()=>{
  fetchBookscheckPayment()
},[loginuser])
  const handle = (item) => {
    console.log('click')
    // const user = localStorage.getItem('user');
    if (user) {
      
        navigate('/AudioBooks/details', { state: { data: item } });
      }
       else {
        // navigate('/get-plans');
        navigate('/AudioBooks/details', { state: { data: item } });
      }
     
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/user/getAudioBooks');
      const formattedData = response.data.data.map((item) => ({
        ...item,
        id: item._id
      }));
      setData(formattedData.reverse());
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Set loading state to false when data is fetched
    }
  };

  useEffect(() => { fetchData(); }, []);

  return (
    <div className='auido-book-main-div' style={{ backgroundColor: 'black' }}>
      <div className='adding-left'>
        <h2 className='h2-main-heading' style={{ width: '100%' }}>Audio Books</h2>
        <div className='main-div-row-audio-books'>
          {isLoading ? (
            // Show skeletons while loading
            <>
              {[...Array(18).keys()].map((_, index) => (
                <div key={index} className='row-audiobook'>
                  <Skeleton className='skeleton-item' sx={{ bgcolor: '#6c757d',marginBottom:1 }} variant="rectangular" >

                  </Skeleton>
                  
                </div>
              ))}
            </>
          ) : (
            data.map((item) => (
                        <div key={item.id} className='row-auidobook' onClick={()=> handle(item)}>
                          <div className='image-div-auido'>
                          <img src={item.coverImage} alt="" className='auido-img-cover-mn-page'  />
                          <div className='booking-first-div-s' id='audio-book-catehoru'>
                            <span className='first'>{item.category?.name}</span> <span className='line-slash'> /</span> <span className='Second'>{item.secondaryCategory?.name}</span>
                          </div>
                          </div>   
                        </div>
                      )))}
        </div>
        <FormUserNotLogin setUser={setUser} show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </div>
  );
}

export default AllAudioBooks;
