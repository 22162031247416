
import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../assets/images/logo.png';
import { NavLink, useNavigate } from 'react-router-dom'; 
import './Header.css';
import { CiSearch } from "react-icons/ci";
import FormHeader from '../components/form/Form'
import Dropdown from './Dropdown';
import { Box, Menu, MenuItem, Tooltip } from '@mui/material';
import { MdOutlineStarPurple500 } from "react-icons/md";
import { HiOutlineBars3 } from "react-icons/hi2";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import defulatimg from '../assets/images/defulat image.png'
function NavScrollExample() {
  const navigate = useNavigate();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewPageVisit = () => {
    localStorage.setItem('lastVisitNewPage', new Date().toISOString());
    setNewBooks(false);
    navigate('/New');
  };


  const handleSearchChange = async (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  
    if (value.length >= 3) {
      setLoading(true);
  
      try {
        const filteredBooks = books.filter(book =>
          book.authorName.toLowerCase().includes(value.toLowerCase()) ||
          book.title.toLowerCase().includes(value.toLowerCase())
        ).sort((a, b) => {
          const searchTermLower = value.toLowerCase();
          const aMatchesAuthor = a.authorName.toLowerCase() === searchTermLower;
          const bMatchesAuthor = b.authorName.toLowerCase() === searchTermLower;
          const aMatchesName = a.title.toLowerCase() === searchTermLower;
          const bMatchesName = b.title.toLowerCase() === searchTermLower;
  
          return aMatchesAuthor || aMatchesName ? -1 : 1;
        });
  
        setSearchResults(filteredBooks);
        setIsDropdownVisible(true);
      } catch (error) {
        console.error('Error searching books:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setIsDropdownVisible(false);
    }
  };
  
  const searchInputRef = useRef(null);
  
 
  
  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.clear();
    localStorage.removeItem('profileimage')
    localStorage.removeItem('userId')
    localStorage.removeItem('token');
    localStorage.removeItem('paymentPlain');
    setUser(null);
    navigate('/');
    window.location.reload(); 
};


  
const [modalShow, setModalShow] = useState(false);
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newBooks, setNewBooks] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [books, setBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(defulatimg); // Default image
  const checktoken = localStorage.getItem('token');
 const ImageProfileLocal = localStorage.getItem("profileimage")
 const validImage = ImageProfileLocal && ImageProfileLocal !== "undefined" && ImageProfileLocal !== "null" && ImageProfileLocal !== "" ? ImageProfileLocal : selectedImage;
 const handleBookClick = (bookData) => {
  setSearchTerm('')
  setIsDropdownVisible(false)
  navigate('/BookDetails', { state: { data: bookData } });
 

};
console.log('selectedImage',user)
const emial = localStorage.getItem('email')
const uploadFile = async (file, type) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post('https://backend.mysecretlit.com/api/uploadFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.data.path; 
    window.location.reload()
  } catch (error) {
    console.error(`Error uploading ${type}:`, error);
    return "";
  }
};
const handleImageUpload = async (event) => {
  const file = event.target.files[0]; // Get the uploaded file
  
  if (file) {
    try {
    
      const imageUrl = await uploadFile(file, 'image');
      localStorage.setItem('profileimage',imageUrl)
      if (imageUrl) {
        await axios.patch('https://backend.mysecretlit.com/api/user/updateProfile', {
          profilePicture: imageUrl, // Send the image URL in the API request
        },{
          headers: {
            Authorization: `Bearer ${checktoken}`,
          },
          
        });
       

        setSelectedImage(imageUrl); // Set new uploaded image in the UI
        
      } else {
        window.location.reload()

      }
    } catch (error) {
      console.error('Error updating profile with image:', error);
    }
  }
  handleClose();
};

  // const navigate = useNavigate();
  const [checkplan, setcheckplan] = useState();
  const [selectedGender, setSelectedGender] = useState('Other'); // Initially set to 'Other'
  
  const open = Boolean(anchorEl);

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const parsedUser = JSON.parse(loggedInUser);
      setUser(parsedUser);
      setSelectedGender(parsedUser?.gender || 'Other'); // Update selectedGender when user is set
    }
    fetchNewBooks();
  }, []);

  useEffect(() => {
    if (user) {
      fetchBookscheckPayment();
    }
  }, [user]);

  const fetchBookscheckPayment = async () => {
    if (!user?.user?._id) return;
    try {
      const response = await axios.get(`https://new-app-testing-2d30280db142.herokuapp.com/api/check-payment-plan/${user?.user?._id}`);
      setcheckplan(response.data);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  const fetchNewBooks = async () => {
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/book/getBooks?type=book');
      const books = response.data?.data;
      const lastVisit = localStorage.getItem('lastVisitNewPage');
      const lastVisitTime = lastVisit ? new Date(lastVisit).getTime() : 0;
      const newBooksAvailable = books.some(book => new Date(book.createdAt).getTime() > lastVisitTime);

      setNewBooks(newBooksAvailable);
      setBooks(response.data.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [angle, setAngle] = useState(0); // Initial angle

  const handleAngleChange = (event) => {
    setAngle(parseInt(event.target.value, 10)); // Set selected angle
  };


  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const handleSearchSubmit = () => {
    if (searchTerm.length >= 3) {
      navigate(`/search-results`, { state: { query: searchTerm } });
      setIsDropdownVisible(false);
    }
  };

  // Event listener for "Enter" keypress
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
      setSearchTerm('')
    }
  };
  useEffect(() => {
    // Function to handle clicks outside the dropdown/search input
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
       setTimeout(()=>{
        setIsDropdownVisible(false);
        setSearchTerm('')
       },[200])
       // Close dropdown if clicked outside
      }
    };
  
    // Add event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      // Cleanup the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
 
    };
  }, []);
  return (
    <div className='header-main-destopheadr' style={{ background: '#1C1C1C' }}>
      <Navbar expand="lg" className="bg-body-tertiary" id='mainHeader'>
        <Container fluid>
          <Navbar.Brand>
            <img src={Logo} alt="" className='header-img' onClick={() => navigate('/')} />    
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
              <NavLink to="/" exact activeClassName="active" className='naviagtion-a'>Home</NavLink>
              <div>
                <Dropdown />
              </div>
              <NavLink to="/Series" exact activeClassName="active" className='naviagtion-a'>Series</NavLink>
              <NavLink to='/AudioBooks' exact activeClassName="active" className='naviagtion-a'>AudioBooks</NavLink>
              <NavLink 
                to='/New' 
                exact 
                activeClassName="active" 
                className='naviagtion-a'
                onClick={handleNewPageVisit}
                style={{ position: 'relative', display: 'flex' }}
              >
                New 
                {newBooks && (
                  <div>
                    <MdOutlineStarPurple500 style={{ color: '#EE2D2F', position: 'absolute', fontSize: '11px' }} />
                  </div>
                )}
              </NavLink>
            </Nav>
            <Form className="d-flex" onSubmit={e => e.preventDefault()}>
              <div className='searchContainer'>
                <input
               
                 ref={searchInputRef}
                 className='searchInput'
                 placeholder="Search books/authors"
                 value={searchTerm}
                 onChange={handleSearchChange}
                 onKeyPress={handleKeyPress}  // Listen for Enter key
                />
 <CiSearch className='searchIcon' onClick={handleSearchSubmit}      style={{ cursor: searchTerm.length > 0 ? 'pointer' : 'default' }}  />                {isDropdownVisible && (
        <div className='dropdown-menu show searchbardropdwon' style={{ position: 'absolute',background:'black',marginLeft:0 }}>
          {loading ? (
            <div className='spinner-container'>
              <Spinner variant="danger" animation="border" />
            </div>
          ) : searchResults.length > 0 ? (
            searchResults.map(book => (
              <div className='dropdown-item-search' onClick={()=>handleBookClick(book)} key={book._id} style={{cursor:"pointer"}}>
                <img className='image-search-books' src={book?.coverImage} alt={book.bookTitle} style={{ width: '50px', height: '50px', marginRight: '10px',objectFit:'cover',objectPosition:'top'}} />
                <div>
                  <p className='search-paragrph-text' >{book?.title}</p>
                  <p  className='authorname-search'>{book?.authorName}</p>
                </div>
              </div>
            ))
          ) : (
            <div className='search-book-notfound-data'>Book not found</div>
          )}
        </div>
      )}
              </div>
                             {user ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                  
                      <a onClick={handleClick} className='btn-header-btnme'><HiOutlineBars3 style={{marginTop:'-4px',fontSize:20}} /> MY</a>
                   
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    disableScrollLock={true}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <div className="main-div-setting-div">
                      <MenuItem id='menuTestImage'  style={{ display: 'block',paddingLeft:'7px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ position: 'relative' }}>
    <img
            src={validImage}
            alt="profile"
            style={{
              boxShadow:'0px 0px 2px 1px red',
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              objectFit: 'cover',
              cursor: 'pointer',
              transform: `rotate(${angle}deg)`, // Apply rotation
              transition: 'transform 0.3s ease-in-out'
            }}
            onClick={() => document.getElementById('imageUpload').click()} // Trigger file input on click
          />
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: 'none' }} // Hidden file input
            onChange={handleImageUpload} // Handle file upload
          />
    </div>
    <div style={{ marginLeft: '10px' }}>
      <p className='paragraph-text-menu' style={{ color: 'white', cursor: 'default' }}>{user.email || emial}</p>
      <NavLink onClick={handleClose} to='/get-plans' style={{ textDecoration: 'none' }}>
        
      </NavLink>
    </div>
  </div>
                        
                      </MenuItem>
                    </div>
                    <MenuItem onClick={handleClose} style={{display:'flex',justifyContent:'center',marginTop:'2px'}}>
                    <NavLink to='/get-plans' style={{textDecoration:'none',background:'transparent'}}>
                    <p
          className='paragraph-text-menu'
          style={{
            fontWeight: '350',
            textShadow:'0px 1px 1px #ff0000',
            color: checkplan?.paymentPlanId ? '#EE2D2F' : '#EE2D2F'
          }}
        >
          {checkplan?.paymentPlanId ? 'Upgrade your membership' : 'Purchase membership now'}
        </p>
        </NavLink>
        </MenuItem>
                    <MenuItem >
                      <div className="row">
                        <div className="colm" style={{ paddingLeft: "10px" ,paddingTop:'5px'}}>
                          <p className='paragraph-text-menu' style={{color:'white',cursor:'default'}}>Gender</p>
                        </div>
                        <div className="colm" style={{ display: 'flex', justifyContent: 'end' }}>
                          <Form.Select
            style={{
              width: '58%',
              fontSize: '12px',
              background: 'black',
              color: 'white',
              textAlign: 'center',
              padding: '0px',
              cursor:'pointer'
            }}
            aria-label="Default select example"
            value={selectedGender} 
            onChange={handleGenderChange} 
          >
            <option value="Other">Other</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Form.Select>
                        </div>  
                      </div>
                    </MenuItem>
                    
                    <MenuItem onClick={handleClose}>
                      <NavLink to='mypage/RecentRead' style={{ textDecoration: 'none', background: 'none' }} className='paragraph-text-menu'>
                        <p className='paragraph-text-menu'>Recently Read</p>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink to='mypage/is-favorite-books' style={{ textDecoration: 'none', background: 'none' }} className='paragraph-text-menu'>
                        <p className='paragraph-text-menu'>Favorite Books</p>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink to='mypage/accountSetting' style={{ textDecoration: 'none', background: 'none' }} className='paragraph-text-menu'>
                        <p className='paragraph-text-menu' style={{fontWeight:400}}>Account Settings</p>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink to='mypage/promotion_code' style={{ textDecoration: 'none', background: 'none' }} className='paragraph-text-menu'>
                        <p className='paragraph-text-menu'>Promo Code</p>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink to='/mypage/customer-services' style={{ textDecoration: 'none', background: 'none' }} className='paragraph-text-menu'>
                        <p className='paragraph-text-menu'>Customer Services</p>
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <p className='paragraph-text-menu'>Logout</p>
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <>
                  <Button variant="outline-success" className='btn-header-btnme' onClick={() => setModalShow(true)}>SIGN IN</Button>
                  <FormHeader show={modalShow} onHide={() => setModalShow(false)} />
                </>
              )}
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <FormHeader show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

export default NavScrollExample;
