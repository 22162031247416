
import React, { useEffect, useState } from 'react';
import './Plains.css';
import ButtonCustom from '../../components/ButtonCustom';
import { Radio } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import FormUserNotLogin from '../form/FormUserNotlogin';
import axios from 'axios';

function Plains() {
  const [selectedPlan, setSelectedPlan] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [user, setUser] = useState(null);
  const [loading,setloading] = useState(false)
  const [data,setdata] = useState([])
  const navigation = useNavigate();
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleProcessClick = () => {
    const user = localStorage.getItem('user');
    if (selectedPlan) {
      if(user){
        navigation('/get-plans/creditoption',{ state: { data:  selectedPlan } });
      }else{
        setModalShow(true)
      }
      
    } 
  };
  const handle = (plan) => {
    const user = localStorage.getItem('user');
    if (selectedPlan) {
      if(user){
        navigation('/get-plans/creditoption',{ state: { data:  plan } });
      }else{
        setModalShow(true)
      }
      
    } 
  };
  const fetchData = async () => {
    setloading(true);
    try {
      const response = await axios.get('https://backend.mysecretlit.com/api/getPlans');
      const formattedData = response.data.data.reverse().map((item, index) => ({
        ...item,
        id: item._id
      }));
      setdata(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch books. Please try again.');
    } finally {
      setloading(false);
    }
  };
  useEffect(()=>{
    fetchData()
  },[])
  return (
    <div className="App">
      <div className="plan-options">
        {data?.map((plan) => (
       
          <PlanOption
           durationBtn={plan?.name?.slice(1,7)}
            key={plan?._id}
            duration={plan?.name?.slice(1)}
            price={plan?.cutPrice}
            selected={selectedPlan?.id === plan.id}
            actualPrice={plan?.price || plan?.pricing}
            btnprice={plan?.price|| plan?.pricing}
            month={plan?.name?.charAt(0)}
            Paratext={plan?.description}
            onSelect={() => handlePlanSelect(plan)}
            Handle={() =>handle(plan)}
          />
        ))}
      </div>
      <div className="btn-main-payment">
        <ButtonCustom Text="Proceed to Payment" OnclickHandle={handleProcessClick}/>
      </div>  
      <ToastContainer pauseOnHover={false} />
      <FormUserNotLogin setUser={setUser} show={modalShow} onHide={() => setModalShow(false)}   />
    </div>
  );
}

function PlanOption({durationBtn, duration, price, selected, onSelect, month, Paratext, Handle, actualPrice, btnprice}) {
  return (
    <div className={`plan-option ${selected ? 'selected' : ''}`} onClick={onSelect}>
      <div className="month">
        <Radio
          className={selected ? 'inputselected' : ''}
          checked={selected}
          name="radio-buttons"
          inputProps={{ 'aria-label': 'B' }}
        />
        <label><span className="onemontg"> {month} </span> {duration}</label>
      </div>
      <div className="textdiv">{Paratext} <span className="priceText"> USD </span><span className="priceText cutPrice">{price}</span> <span className="priceText">{actualPrice}</span></div>
      <div className="buttonPlains" onClick={Handle}> 
        <div className={`btn-color ${selected ? 'selected' : ''}`}>  <span className='btn-span-plain'>USD {btnprice} </span> <span > / </span> 
        {durationBtn}</div>
      </div>
    </div>
  );
}

export default Plains;
