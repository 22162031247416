import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate,} from 'react-router-dom';
import { IoStar } from "react-icons/io5";
import './AudioBookPlayer.css';
import { IoStarOutline } from "react-icons/io5";
import { Rating } from '@mui/material';
import BookCardTem from '../../components/BookCardtem/BookCardTem';
import 'react-toastify/dist/ReactToastify.css';
import { BookingDetailsData } from '../../Obj';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
const BookDetails = () => {
  
    const location = useLocation()
    const { data } = location.state || {};
    const [showFullText, setShowFullText] = useState(false);   
    const [bookdata,setbookdata] = useState({})
    const [loading, setLoading] = useState(false);
    console.log('bookdata',bookdata)
    useEffect(() => {
        window.scrollTo(0, 0);
        apiFatchBook(data._id)
    }, []);
    const apiFatchBook = async (id) => {
      try {
      const response = await axios.get(`https://backend.mysecretlit.com/api/user/getBookById?id=${id}`)  
      setbookdata(response.data.data)
      } catch (error) {
        console.log('error',error)
      }
        }
        const handleDownload = async () => {
          const audioUrl = data?.mediaUrl || bookdata?.mediaUrl;
          const fileName = `${data?.title?.replace(/\s+/g, '-').toLowerCase() || 'audio-book'}${
            audioUrl?.endsWith('.mp3') ? '.mp3' : '.m4b'
          }`;
      
          setLoading(true); // Start spinner
      
          try {
            const response = await fetch(audioUrl);
            const blob = await response.blob();
      
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      
            setLoading(false); // Stop spinner once download is complete
          } catch (error) {
            console.error('Error downloading the file', error);
            setLoading(false); // Stop spinner in case of error
          }
        };
    return (
    <div className="booking-detils">
        <div className="main-div-detils">
<div className="row-detials">
    <div className="colmTwo imgcolm">
        <img src={data?.coverImage} alt="" className='image-detils' />
    </div>
    <div className="colmTwo" style={{textAlign:'left'}}>
        <div className="text-heading">
     <h3 className="heaingDetailsMain">{data?.title}</h3>   
        </div>
        <h6 className="author-name-class">Author Name: {data?.authorName}</h6>
        <div style={{textAlign:'left',width:'80%',color:'white'}}><span className='first'>
                  {data?.category?.name || bookdata?.category?.name}</span> <span className='line-slash'>/</span> <span className='Second'>{data?.secondaryCategory?.name || bookdata?.secondaryCategory?.name}</span></div>
                  <p className='reviewDiv'>Reviews</p>
                  <div className="rating-diiv">
                    
<Rating name="read-only"  value={data?.rating || bookdata?.rating}
    precision={data?.rating || bookdata?.rating}   readOnly className='rating-star-new'  icon={<IoStar className='rating-star-new' />} emptyIcon={<IoStarOutline  className='rating-star-new' style={{color:'#ADADAD'}} />} />
                
                <p className="ratingTextdetails">({data?.rating || bookdata?.rating})</p>
                
                </div>
               
    </div>
   


</div>
<div className="description-div">
<h2 className='discription-heading-h2'>Description</h2>
<div className="row-description-readmore">
  
<p className={`description-para ${showFullText ? 'show' : 'hide'}`}>
{data?.longDescription}  
{data?.despriction ? data : `
                `   }
                </p>
          
</div>
<div className='test'>


<div className="ButtonDivPdf" id='check-pdf-right-btn'>


<audio className='auido-player-div-' id="audio-player" controls >         
         <source src={data?.mediaUrl|| bookdata.mediaUrl } type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>    
   </div>
   
   <div className='audio-div-downlood-btn'>
{/* <a
                href={data?.mediaUrl|| bookdata.mediaUrl  }
                download={`${data?.name?.replace(/\s+/g, '-')}.m4b`}
                className="downlood-btn-audio-book"
              > */}
              {/* <a
    href={data?.mediaUrl || bookdata?.mediaUrl}
    download={`${
      data?.title?.replace(/\s+/g, '-').toLowerCase() || 'audio-book'
    }${(data?.mediaUrl || bookdata?.mediaUrl)?.endsWith('.mp3') ? '.mp3' : '.m4b'}`}
    className="downlood-btn-audio-book"
  >
                Download
              </a>     */}
             <button
        className="downlood-btn-audio-book"
        onClick={handleDownload}
        disabled={loading} // Disable button while downloading
      >
        {loading ? ( 
          // Show spinner if loading is true
          <Spinner 
            as="span" 
            animation="border" 
            size="sm" 
            role="status" 
            aria-hidden="true" 
            style={{ marginRight: '5px', color: 'white' }}
          />
        ) : (
          // Show "Download" text if not loading
          'Download'
        )}
      </button>
                </div>
</div>

                </div>
<div className="releatedBooksaudiobook">
<h2 className='discription-heading-h2' >You May Also Like</h2>
<div className='relaed-book-show-gap' >
          {BookingDetailsData?.map((card, index) => (
            <div key={index} className='custom-deatils-card' >
              <BookCardTem color={false}
                Bookname={card.Bookname}
                firstTopTag={card.FirstTopTag}
                secTopTag={card.SecTopTag}
                bookcategoriesFirst={card.bookcategoriesFirst}
                bookcategoriesSec={card.bookcategoriesSec}
                rating={card.rating}
                bookImg={card.bookImg}
              />
            </div>
          ))}
        </div>
</div>

</div>

        </div>
   
  )
}

export default BookDetails
