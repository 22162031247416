import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './all.css'; // Import CSS styles
import Form from 'react-bootstrap/Form';
import { IoIosArrowDown } from "react-icons/io";
const ContactForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [name, setName] = useState('');
  const [Subject,setSubject] = useState('')
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(''); // New state for email error

  const validateEmail = async (email) => {
    try {
      const response = await axios.get(`https://api.hunter.io/v2/email-verifier?email=${email}&api_key=b5e390d88d0f80732c8adbcc3056681cb3134db8`);
      return response.data.data.status === 'valid';
    } catch (error) {
      console.error('Email validation failed:', error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const isEmailValid = await validateEmail(email);
    if (!isEmailValid) {
      setEmailError('Invalid email address. Please enter a valid email.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://backend.mysecretlit.com/api/contactus', {
        name,
        email,
        subject:Subject,
        message
      });
      console.log('response',response.data)
      toast.success('Message sent successfully!');
      setName('');
      setEmail('');
      setMessage('');
      setSubject("")
      setEmailError(''); // Clear email error after successful submission
    } catch (error) {
      toast.error('Error sending message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value.trim();
    setName(value);
    updateButtonState(value, email, message);
  };
  const handlesubjectChnage = (event) => {
    const value = event.target.value.trim();
    setSubject(value);
    updateButtonState(value, email, message);
  };

  const handleInputChangere = (event) => {
    const value = event.target.value.trim();
    setEmail(value);
    updateButtonState(name, value, message);
  };

  const handleInputChangeconform = (event) => {
    const value = event.target.value.trim();
    setMessage(value);
    updateButtonState(name, email, value);
  };

  const updateButtonState = (name, email, message) => {
    const isFilled = name.length > 0 && email.length > 0 && message.length > 0;
    setIsButtonDisabled(!isFilled);
  };

  const buttonStyle = {
    backgroundColor: isButtonDisabled ? 'rgb(217 82 84)' : '#EE2D2F',
    color: 'white',
    cursor: isButtonDisabled ? 'default' : 'pointer',
    fontFamily: '"Noto Sans SC", sans-serif',
    padding: '7px 20px',
    width: '80%',
    borderRadius: 50,
    border: 'none',
    fontSize: 16
  };

  const emailInputStyle = {
    borderColor: emailError ? 'red' : '#ccc',
    borderWidth: 1,
    borderStyle: 'solid',
    color: 'white'
  };

  return (
    <div className="booking-detils">
      <div className="contact-form-container">
        <h2 className='discription-heading-h2 getVip' style={{color:'white'}}>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          {/* Name input field */}
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleInputChange}
              required
              placeholder='Name'
            />
          </div>
          {/* Email input field */}
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleInputChangere}
              required
              placeholder='Email'
              style={emailInputStyle}
            />
            {emailError && <p style={{ color: 'red', fontSize: '0.8em' }}>{emailError}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="name">Subject:</label>
            <input
              type="text"
              id="name"
              value={Subject}
              onChange={handlesubjectChnage}
              required
              placeholder='Subject'
            />
          </div>
          {/* Message input field */}
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={handleInputChangeconform}
              required
              style={{ color: 'white', marginBottom: 20 }}
              placeholder='Message'
            ></textarea>
          </div>
          {/* Submit button */}
          <div className='btn-contact-pg'>
            <button style={buttonStyle} disabled={isButtonDisabled || loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
            
          </div>
        </form>
      
      </div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover={false}
theme="dark"
/>
    </div>
  );
};

export default ContactForm;
